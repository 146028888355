<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
      <CTab active>
        <template slot="title">
          <CIcon name="cil-task"/>&nbsp;Filtre
        </template>
        <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
          <CRow>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol>
                <CInput
                    label="Oluşt. Tarihi"
                    horizontal
                    style="width: 50%"
                    type="date"
                    v-model="data.startTime"
                />
                <CInput
                    label=" "
                    horizontal
                    style="width: 50%"
                    type="date"
                    v-model="data.endTime"
                />
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol sm="6">
                <CInput
                    label="Sipariş No"
                    placeholder="000000"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.productionOrder"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                    label="Sipariş ID"
                    placeholder="ID"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.productionOrderId"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                    label="Model"
                    placeholder="Model"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.productName"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                    label="Ürün Tipi"
                    placeholder="Ürün tipi"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.productTypeName"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                    label="Üretici Kodu"
                    placeholder="Üretici kodu"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.producerCode"
                />
              </CCol>
              <CCol sm="6">
                <CSelect
                    label="Denetim"
                    horizontal
                    :options="comboDataList"
                    :value.sync='data.inspectionFlag'
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                    label="Sevkiyat No"
                    placeholder="000000"
                    horizontal
                    type="text"
                    v-on:keyup.enter="filter"
                    v-model="data.shipmentNumber"
                />
              </CCol>
            </CRow>
            
        </CForm>
      </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "OrderFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               comboDataList: [
                {label: "Hepsi", value: ""},
                {label: "Evet", value: "true"},
                {label: "Hayır", value: "false"}],
              }
       },
       methods:{
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>